import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("deviceTemplates", [
      "deviceTemplates",
      "selectedDeviceTemplate",
    ]),
  },
  methods: {
    displayDeviceTemplate(id) {
      let idToUse = id;
      // editing is used in edit profile
      if (this.editing && this.selectedDeviceTemplate) {
        idToUse = this.selectedDeviceTemplate.id;
      }
      if (
        this.deviceTemplates &&
        this.deviceTemplates.data &&
        this.deviceTemplates.data.length
      ) {
        const filtered = this.deviceTemplates.data.filter(
          (item) => idToUse === item.id
        );
        if (filtered && filtered.length) {
          return filtered[0].name;
        } else {
          return null;
        }
      }
      return null;
    },
    getDeviceTemplate(id) {
      let idToUse = id;
      // editing is used in edit profile
      if (this.editing && this.selectedDeviceTemplate) {
        idToUse = this.selectedDeviceTemplate.id;
      }
      if (
        this.deviceTemplates &&
        this.deviceTemplates.data &&
        this.deviceTemplates.data.length
      ) {
        const filtered = this.deviceTemplates.data.filter(
          (item) => idToUse === item.id
        );
        if (filtered && filtered.length) {
          return filtered[0];
        } else {
          return null;
        }
      }
      return null;
    },
    openDeviceTemplate(deviceTemplate, url) {
      if (!this.selectedDeviceTemplate) {
        this.$store.commit(
          "deviceTemplates/setSelectedDeviceTemplate",
          this.deviceTemplates.data.find((t) => t.id == deviceTemplate),
          {
            root: true,
          }
        );
      }

      this.$router.push({
        name: url,
      });
    },
  },
};
