<template>
  <mobile-screen
    :header="true"
    screen-class="resources-screen gray-bg icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_resources-help-online-edit-function',
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "resources",
              "resource-function",
              "resource-function"
            )
          }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      v-if="functionData"
      :key="formKey"
      ref="editResourceFunctionForm"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      :initial-values="{ ...functionData }"
      novalidate
    >
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl">
              <dt class="clebex-item-dt">
                {{ displayLabelName("resources", "edit-resource", "name") }}
              </dt>
              <dd class="clebex-item-dd">
                <span v-if="functionData.name">{{ functionData.name }}</span>
                <span
                  v-else-if="
                    functionData.resource_function_type &&
                      functionData.resource_function_type.name
                  "
                  >{{ functionData.resource_function_type.name }}</span
                >
              </dd>
            </dl>
          </div>
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="resourceConnectorCurrent"
                    type="checkbox"
                    v-model="isActive"
                  />
                  <label for="resourceConnectorCurrent"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{
                  displayLabelName(
                    "resources",
                    "resource-function",
                    "is-active"
                  )
                }}
              </dd>
            </dl>
          </div>
        </li>
        <li
          class="clebex-item-section-item full-right-underline"
          v-if="functionData.last_activity_at"
        >
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl">
              <dt class="clebex-item-dt">
                {{
                  displayLabelName(
                    "resources",
                    "resource-function",
                    "last-access"
                  )
                }}
              </dt>
              <dd class="clebex-item-dd">
                {{ functionData.last_activity_at }}
              </dd>
            </dl>
          </div>
        </li>
        <li
          v-if="
            functionData.codes_count === 'SINGLE' &&
              functionData.type !== 'CLEAN' &&
              !Object.keys(alternateLayoutFunctions).includes(functionData.type)
          "
          class="clebex-item-section-item full-right-underline"
          :class="{ 'has-error': errors['resource_function_codes[0].code'] }"
        >
          <div
            class="error-message"
            v-if="errors['resource_function_codes[0].code']"
          >
            {{ errors["resource_function_codes[0].code"] }}
          </div>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="labelForCode"
                >{{
                  displayLabelName("resources", "resource-function", "code")
                }}
                <span>*</span></label
              >
              <Field
                name="resource_function_codes[0].code"
                id="labelForCode"
                as="input"
                :rules="functionData.codes_min ? 'required' : ''"
                type="text"
                :disabled="
                  activeFunction && activeFunction.type === 'MAP_OBJECT'
                "
                :placeholder="
                  displayLabelName('resources', 'resource-function', 'code')
                "
              />
            </div>
          </div>
        </li>
        <li
          @click="alternateFunctionsPage(functionData.type)"
          v-if="
            functionData.type === 'LINKED' &&
              isActive &&
              resourceLinked &&
              resourceLinked.length
          "
          class="clebex-item-section-item full-right-underline"
        >
          <div class="clebex-item-content-wrapper alternate">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "resources",
                  "resource-function",
                  "linked-resources"
                )
              }}</label>
              <p class="text-label">
                {{
                  resourceLinked
                    .filter((resource) => resource.is_linked)
                    .map((item) => `${item.resource_name}`)
                    .join(", ")
                }}
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </div>
        </li>
        <li
          v-if="
            functionData.type === 'LINKED' &&
              isActive &&
              resourceLinked &&
              resourceLinked.linkedTo
          "
          class="clebex-item-section-item full-right-underline"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName("resources", "resource-function", "linked-to")
              }}</label>
              <p class="text-label">
                {{
                  resourceLinked.linkedTo
                    .map((item) => `${item.resource_name}`)
                    .join(", ")
                }}
              </p>
            </div>
          </div>
        </li>
        <li
          @click="alternateFunctionsPage(functionData.type)"
          v-if="
            functionData.type === 'DISTANCE' &&
              isActive &&
              resourceDistances &&
              resourceDistances.length
          "
          class="clebex-item-section-item full-right-underline"
        >
          <div class="clebex-item-content-wrapper alternate">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "resources",
                  "resource-function",
                  "attached-resources"
                )
              }}</label>
              <p class="text-label">
                {{
                  resourceDistances
                    .filter((resource) => resource.is_attached)
                    .map((item) => `${item.resource_name}`)
                    .join(", ")
                }}
              </p>
            </div>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </div>
        </li>
        <template
          v-if="
            functionData.codes_count !== 'SINGLE' &&
              functionData.resource_function_codes &&
              functionData.resource_function_codes.length &&
              !Object.keys(alternateLayoutFunctions).includes(functionData.type)
          "
        >
          <li
            v-for="(code, idx) in functionData.resource_function_codes"
            :key="code.id"
            class="clebex-item-section-item full-right-underline"
            :class="{
              'has-error': errors[`resource_function_codes[${idx}].code`],
            }"
            :style="[idx > 8 ? 'border-bottom: 0px !important' : '']"
          >
            <!-- Start Language -->
            <div
              v-if="functionData.type === 'DISPLAY' && idx == 3"
              class="clebex-item-section-item full-right-underline"
            >
              <div
                @click="
                  openLanguage(
                    functionData.resource_function_codes[3].code,
                    'r_edit-resource-edit-function-language'
                  )
                "
                class="clebex-item-content-wrapper"
                style="cursor: pointer;"
              >
                <div class="clebex-section-input" style="cursor: pointer;">
                  <label
                    class="clebex-section-input-label"
                    style="cursor: pointer;"
                    >{{
                      displayLabelName(
                        "visitor-management",
                        "manage-reception-desks",
                        "language"
                      )
                    }}</label
                  >
                  <p class="text-label ellipsis" style="cursor: pointer;">
                    <template v-if="selectedLanguage">
                      {{ displayLanguage(selectedLanguage.id) }}
                    </template>
                    <template v-else-if="functionData">
                      {{
                        displayLanguage(
                          parseInt(functionData.resource_function_codes[3].code)
                        )
                      }}
                    </template>
                    <template v-else></template>
                  </p>
                </div>
                <span class="follow-up-icons" style="cursor: pointer;">
                  <span class="follow-up-icon-item">
                    <icon
                      icon="#cx-app1-arrow-right-12x12"
                      width="12"
                      height="12"
                    ></icon>
                  </span>
                </span>
              </div>
            </div>
            <!-- End Language -->
            <!-- Start Template -->
            <div
              v-else-if="functionData.type === 'DISPLAY' && idx == 6"
              class="clebex-item-section-item full-right-underline"
              style="cursor: pointer;"
            >
              <div
                style="cursor: pointer;"
                @click="
                  openDeviceTemplate(
                    functionData.resource_function_codes[6].code,
                    'r_edit-resource-edit-function-device-template'
                  )
                "
                class="clebex-item-content-wrapper"
              >
                <div class="clebex-section-input" style="cursor: pointer;">
                  <label
                    class="clebex-section-input-label"
                    style="cursor: pointer;"
                    >{{
                      displayLabelName(
                        "visitor-management",
                        "manage-reception-desks",
                        "template"
                      )
                    }}</label
                  >
                  <p class="text-label ellipsis" style="cursor: pointer;">
                    <template v-if="selectedDeviceTemplate">
                      {{ selectedDeviceTemplate.name }}
                    </template>
                    <template v-else-if="functionData">
                      {{
                        displayDeviceTemplate(
                          parseInt(functionData.resource_function_codes[6].code)
                        )
                      }}
                    </template>
                    <template v-else></template>
                  </p>
                </div>
                <span class="follow-up-icons">
                  <span class="follow-up-icon-item">
                    <icon
                      icon="#cx-app1-arrow-right-12x12"
                      width="12"
                      height="12"
                    ></icon>
                  </span>
                </span>
              </div>
            </div>
            <!-- End Template -->
            <!-- Start Currency -->
            <div
              v-else-if="functionData.type === 'COST' && idx == 1"
              class="clebex-item-section-item full-right-underline"
            >
              <div
                @click="
                  openCurrency(
                    parseInt(functionData.resource_function_codes[1].code),
                    'r_edit-resource-edit-function-currency'
                  )
                "
                style="cursor: pointer;"
                class="clebex-item-content-wrapper"
              >
                <div class="clebex-section-input" style="cursor: pointer;">
                  <label
                    class="clebex-section-input-label"
                    style="cursor: pointer;"
                    >{{ displayLabelName("global.currency.currency") }}</label
                  >
                  <p class="text-label ellipsis" style="cursor: pointer;">
                    <template v-if="selectedCurrency">
                      {{ selectedCurrency.name }} ({{
                        selectedCurrency.symbol
                      }})
                    </template>
                    <template v-else-if="functionData">
                      {{
                        displayCurrency(
                          parseInt(functionData.resource_function_codes[1].code)
                        )
                      }}
                    </template>
                    <template v-else></template>
                  </p>
                </div>
                <span class="follow-up-icons" style="cursor: pointer;">
                  <span class="follow-up-icon-item">
                    <icon
                      icon="#cx-app1-arrow-right-12x12"
                      width="12"
                      height="12"
                    ></icon>
                  </span>
                </span>
              </div>
            </div>
            <!-- End Currency -->
            <div v-else>
              <div
                class="error-message"
                v-if="errors[`resource_function_codes[${idx}].code`]"
              >
                {{ errors[`resource_function_codes[${idx}].code`] }}
              </div>
              <div
                class="clebex-item-content-wrapper"
                v-if="activeFunction.type === 'DISPLAY' && idx < 9"
              >
                <div class="clebex-section-input">
                  <label
                    class="clebex-section-input-label"
                    :for="`labelForCode${idx}`"
                    >{{
                      displayLabelName(
                        "resources",
                        "resource-function",
                        getResourceFunctionProperty(idx)
                      )
                    }}
                    <span v-if="activeFunction.type === 'COST' && idx == 0"
                      >*</span
                    >
                  </label>

                  <Field
                    :name="`resource_function_codes[${idx}].code`"
                    :id="`labelForCode${idx}`"
                    as="input"
                    type="text"
                    :disabled="
                      (activeFunction &&
                        activeFunction.type === 'MAP_OBJECT') ||
                        (activeFunction.type === 'DISPLAY' && idx == 8)
                    "
                    :rules="
                      setCodeFieldRequired(
                        activeFunction &&
                          activeFunction.type === 'COST' &&
                          functionData.codes_min,
                        functionData.resource_function_codes &&
                          functionData.resource_function_codes.length &&
                          functionData.resource_function_codes[0].code == null
                      )
                    "
                  />
                  <span
                    class="follow-up-icons"
                    :style="[
                      activeFunction.type === 'DISPLAY' && idx == 8
                        ? { height: '0px' }
                        : '',
                    ]"
                    v-if="activeFunction.type === 'DISPLAY' && idx == 8"
                    @click="
                      copyFieldValue(
                        functionData.resource_function_codes[8].code
                      )
                    "
                  >
                    <icon
                      icon="#cx-app1-copy"
                      style="cursor: pointer; position: relative; top: -38px;"
                    ></icon>
                  </span>
                </div>
              </div>
            </div>
          </li>
        </template>
        <!-- template for COST function - ADD -->
        <tempate
          v-if="
            activeFunction.type == 'COST' &&
              !functionData.resource_function_codes
          "
        >
          <li
            class="clebex-item-section-item full-right-underline"
            :class="{
              'has-error': errors[`resource_function_codes[${0}].code`],
            }"
          >
            <div
              class="error-message"
              v-if="errors[`resource_function_codes[${0}].code`]"
            >
              {{ errors[`resource_function_codes[${0}].code`] }}
            </div>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label
                  class="clebex-section-input-label"
                  :for="`labelForcode${0}dynamic`"
                  >{{
                    displayLabelName(
                      "resources",
                      "resource-function",
                      "price-per-hour"
                    )
                  }}
                  <span>*</span>
                </label>
                <Field
                  :name="
                    `resource_function_codes[${
                      functionData.resource_function_codes &&
                      functionData.resource_function_codes.length
                        ? functionData.resource_function_codes.length + 0
                        : 0
                    }].code`
                  "
                  :id="`labelForcode${0}dynamic`"
                  as="input"
                  type="text"
                  :rules="
                    setCodeFieldRequired(
                      functionData.codes_min,
                      functionData.resource_function_codes &&
                        functionData.resource_function_codes.length
                        ? functionData.resource_function_codes.length + 0
                        : 0
                    )
                  "
                />
              </div>
            </div>
          </li>
          <li>
            <div
              v-if="
                activeFunction.type == 'COST' &&
                  !functionData.resource_function_codes
              "
              class="clebex-item-section-item full-right-underline"
            >
              <div
                @click="
                  openCurrency(
                    parseInt(company.data.currency_id),
                    'r_edit-resource-edit-function-currency'
                  )
                "
                style="cursor: pointer;"
                class="clebex-item-content-wrapper"
              >
                <div class="clebex-section-input" style="cursor: pointer;">
                  <label
                    class="clebex-section-input-label"
                    style="cursor: pointer;"
                    >{{ displayLabelName("global.currency.currency") }}</label
                  >
                  <p class="text-label ellipsis" style="cursor: pointer;">
                    <template v-if="selectedCurrency">
                      {{ selectedCurrency.name }} ({{
                        selectedCurrency.symbol
                      }})
                    </template>
                    <template v-else-if="functionData">
                      {{ displayCurrency(parseInt(company.data.currency_id)) }}
                    </template>
                    <template v-else></template>
                  </p>
                </div>
                <span class="follow-up-icons" style="cursor: pointer;">
                  <span class="follow-up-icon-item">
                    <icon
                      icon="#cx-app1-arrow-right-12x12"
                      width="12"
                      height="12"
                    ></icon>
                  </span>
                </span>
              </div>
            </div>
          </li>
        </tempate>
        <template
          v-if="
            activeFunction.type !== 'DISPLAY' &&
              activeFunction.type !== 'COST' &&
              functionData.codes_count === 'MULTIPLE' &&
              !Object.keys(alternateLayoutFunctions).includes(functionData.type)
          "
        >
          <li
            v-for="(num, idx) in fieldNum"
            :key="num"
            class="clebex-item-section-item full-right-underline"
            :class="{
              'has-error':
                errors[
                  `resource_function_codes[${
                    functionData.resource_function_codes &&
                    functionData.resource_function_codes.length
                      ? functionData.resource_function_codes.length + idx
                      : idx
                  }].code`
                ],
            }"
          >
            <div
              class="error-message"
              v-if="
                errors[
                  `resource_function_codes[${
                    functionData.resource_function_codes &&
                    functionData.resource_function_codes.length
                      ? functionData.resource_function_codes.length + idx
                      : idx
                  }].code`
                ]
              "
            >
              {{
                errors[
                  `resource_function_codes[${
                    functionData.resource_function_codes &&
                    functionData.resource_function_codes.length
                      ? functionData.resource_function_codes.length + idx
                      : idx
                  }].code`
                ]
              }}
            </div>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label
                  class="clebex-section-input-label"
                  :for="`labelForcode${idx}dynamic`"
                  >{{
                    displayLabelName("resources", "resource-function", "code")
                  }}
                  <span>*</span></label
                >
                <Field
                  :name="
                    `resource_function_codes[${
                      // functionData.resource_function_codes &&
                      // functionData.resource_function_codes.length
                      //   ? functionData.resource_function_codes.length + idx
                      //   :
                      idx
                    }].code`
                  "
                  :id="`labelForcode${idx}dynamic`"
                  as="input"
                  :rules="
                    setCodeFieldRequired(
                      functionData.codes_min,
                      functionData.resource_function_codes &&
                        functionData.resource_function_codes.length
                        ? functionData.resource_function_codes.length + idx
                        : idx
                    )
                  "
                  type="text"
                  :disabled="
                    activeFunction && activeFunction.type === 'MAP_OBJECT'
                  "
                  :placeholder="
                    displayLabelName('resources', 'resource-function', 'code')
                  "
                />
              </div>
            </div>
          </li>
          <li class="clebex-item-section-item full-right-underline">
            <button
              class="clebex-item-content-wrapper"
              type="button"
              @click="addNewItem"
            >
              <span class="label">{{
                displayLabelName(
                  "resources",
                  "resource-capacity-type-time-types",
                  "add-new-item"
                )
              }}</span>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <!-- TODO missing icon -->
                  <svg-icon icon="add"></svg-icon>
                </span>
              </span>
            </button>
          </li>
        </template>
        <!-- Template for calendar - START -->
        <template
          v-if="
            functionData.subscription &&
              functionData.subscription.length &&
              !Object.keys(alternateLayoutFunctions).includes(functionData.type)
          "
        >
          <li
            class="clebex-item-section-item full-right-underline"
            v-if="functionData.subscription[0].expiration_date"
          >
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="expDate">{{
                  displayLabelName(
                    "resources",
                    "resource-function",
                    "expiration-date"
                  )
                }}</label>
                <Field
                  name="subscription[0].expiration_date"
                  id="expDate"
                  as="input"
                  type="text"
                  disabled
                />
              </div>
            </div>
          </li>
          <li
            class="clebex-item-section-item full-right-underline"
            v-if="functionData.subscription[0].resource"
          >
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="subResource">{{
                  displayLabelName("resources.resource-function.resource")
                }}</label>
                <Field
                  name="subscription[0].resource"
                  id="subResource"
                  as="input"
                  type="text"
                  disabled
                />
              </div>
            </div>
          </li>
          <li
            class="clebex-item-section-item full-right-underline"
            v-if="functionData.subscription[0].uuid"
          >
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="subUuid">{{
                  displayLabelName("resources.resource-function.uuid")
                }}</label>
                <Field
                  name="subscription[0].uuid"
                  id="subResource"
                  as="input"
                  type="text"
                  disabled
                />
              </div>
            </div>
          </li>
        </template>
        <!-- Template for calendar - END -->
      </ul>
      <!-- Delete tokens (For DISPLAY) - START -->
      <div
        style="width: 100%; color: #999999; padding: 10px; padding-right: 20px; text-align: right;"
        v-if="activeFunction.type == 'DISPLAY'"
      >
        <span style="cursor: pointer;" @click="deleteDeviceTokenModal = true"
          >{{
            displayLabelName("resources.resource-function.delete-token")
          }}
          ({{
            (usedTokens ? usedTokens : 0) + "/" + (tokens ? tokens : 0)
          }})</span
        >
      </div>
      <!-- Delete tokens (For DISPLAY) - END -->
      <!-- Anonymous services - START -->

      <span
        v-if="activeFunction.type == 'DISPLAY'"
        class="anonymous-services"
        style="margin-top: 20px; margin-left: 15px;"
        >{{
          displayLabelName("resources.resource-function.anonymous-services")
        }}</span
      >
      <ul class="clebex-item-section" style="margin-top: 10px;">
        {{
          resource_function_codes
        }}
        <template v-if="activeFunction.type == 'DISPLAY'">
          <!-- Is active -->
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <dl class="clebex-item-dl justify-start">
                <dt class="clebex-item-dt">
                  <div class="checkbox slide">
                    <input
                      id="anonymousServices"
                      type="checkbox"
                      v-model="anonymousServicesIsActive"
                    />
                    <label for="anonymousServices"></label>
                  </div>
                </dt>
                <dd class="clebex-item-dd justify-start">
                  {{
                    displayLabelName(
                      "resources",
                      "resource-function",
                      "is-active"
                    )
                  }}
                </dd>
              </dl>
            </div>
          </li>
          <!-- Screen URL -->
          <li
            class="clebex-item-section-item full-right-underline"
            v-show="
              resourceResourceFunction &&
                resourceResourceFunction.data &&
                resourceResourceFunction.data.resource_function_codes[9].code ==
                  '1'
            "
          >
            <div
              class="error-message"
              v-if="errors[`resource_function_codes[${idx}].code`]"
            >
              {{ errors[`resource_function_codes[${idx}].code`] }}
            </div>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label
                  class="clebex-section-input-label"
                  :for="`labelForCode${8}`"
                  >{{
                    displayLabelName(
                      "resources",
                      "resource-function",
                      getResourceFunctionProperty(8)
                    )
                  }}
                </label>

                <Field
                  :name="`resource_function_codes[10].code`"
                  :id="`labelForCode${10}`"
                  as="input"
                  type="text"
                  :disabled="true"
                />
                <span
                  class="follow-up-icons"
                  style="height: 0px"
                  @click="
                    copyFieldValue(
                      functionData.resource_function_codes[10].code
                    )
                  "
                >
                  <icon
                    icon="#cx-app1-copy"
                    style="cursor: pointer; position: relative; top: -38px;"
                  ></icon>
                </span>
              </div>
            </div>
          </li>
          <!-- Password -->
          <li
            class="clebex-item-section-item full-right-underline"
            v-show="
              resourceResourceFunction &&
                resourceResourceFunction.data &&
                resourceResourceFunction.data.resource_function_codes[9].code ==
                  '1'
            "
          >
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label
                  class="clebex-section-input-label"
                  :for="`labelForCode${9}`"
                  >{{
                    displayLabelName(
                      "resources",
                      "resource-function",
                      getResourceFunctionProperty(9)
                    )
                  }}
                </label>

                <Field
                  :name="`resource_function_codes[11].code`"
                  :id="`labelForCode${11}`"
                  as="input"
                  type="text"
                />
              </div>
            </div>
          </li>
        </template>
      </ul>
      <!-- Anonymous services - END -->
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
    <screen-modal
      v-if="deleteDeviceTokenModal"
      class="confirm-modal"
      type="success"
      :confirm-action="deleteDeviceToken"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="deleteDeviceTokenModal"
      @close="deleteDeviceTokenModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("resources.messages.delete-device-token-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("resources.messages.delete-device-token-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapGetters, mapState } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import { formatDate } from "@/services/helpers";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import languagesMixin from "@/services/mixins/languages/languages-mixin";
import deviceTemplateMixin from "@/services/mixins/device_templates/device-template";
import currenciesMixin from "@/services/mixins/currencies/currencies-mixin";

export default {
  name: "ResourceFunction",
  mixins: [
    helpOnlineMixin,
    languagesMixin,
    deviceTemplateMixin,
    currenciesMixin,
  ],
  data() {
    return {
      deleteDeviceTokenModal: false,
      fieldNum: [1],
      formKey: 0,
      isActive: false,
      anonymousServicesIsActive: false,
      alternateLayoutFunctions: { LINKED: false, DISTANCE: false },
      helpSlug: "resources-functions-serial",
    };
  },
  computed: {
    ...mapState("resource", [
      "resourceFunctionTypes",
      "resourceFunctionType",
      "resource",
      "resourceResourceFunctions",
      "resourceResourceFunction",
      "resourceLinked",
      "resourceDistances",
    ]),
    ...mapState("user", ["company"]),
    ...mapState("language", ["languages"]),
    ...mapState("currency", ["currencies"]),
    ...mapState("deviceTemplates", ["selectedDeviceTemplate"]),
    ...mapGetters("resource", ["activeResourceFunctionTypes"]),
    tokens() {
      const { resourceResourceFunction } = this;
      if (resourceResourceFunction && resourceResourceFunction.data) {
        if (
          resourceResourceFunction.data.resource_function_codes &&
          resourceResourceFunction.data.resource_function_codes[7]
        ) {
          return resourceResourceFunction.data.resource_function_codes[7].code;
        }
      }
      return 0;
    },
    usedTokens() {
      const { resourceResourceFunction } = this;
      if (resourceResourceFunction && resourceResourceFunction.data) {
        if (
          resourceResourceFunction.data.resource_function_codes &&
          resourceResourceFunction.data.resource_function_codes[7]
        ) {
          return resourceResourceFunction.data.resource_function_codes[7]
            .used_tokens;
        }
      }
      return 0;
    },
    functionData() {
      const { resourceResourceFunction } = this;
      if (resourceResourceFunction && resourceResourceFunction.data) {
        if (this.activeFunction) {
          if (
            resourceResourceFunction.data.subscription &&
            resourceResourceFunction.data.subscription.length
          ) {
            // format subscription data
            return {
              ...this.activeFunction,
              ...resourceResourceFunction.data,
              subscription: [
                {
                  ...resourceResourceFunction.data.subscription[0],
                  expiration_date: formatDate(
                    resourceResourceFunction.data.subscription[0]
                      .expiration_date,
                    "DD.MM.YYYY HH:mm"
                  ),
                },
              ],
            };
          } else {
            return {
              ...this.activeFunction,
              ...resourceResourceFunction.data,
            };
          }
        } else {
          return resourceResourceFunction.data;
        }
      } else if (this.activeFunction) {
        return this.activeFunction;
      }
      return null;
    },
    activeFunction() {
      const { activeResourceFunctionTypes } = this;
      const { function_id } = this.$route.params;
      if (
        activeResourceFunctionTypes &&
        activeResourceFunctionTypes &&
        activeResourceFunctionTypes.length
      ) {
        const filtered = activeResourceFunctionTypes.filter(
          (item) => item.id === Number(function_id)
        );
        if (filtered) {
          return filtered[0];
        }
        return null;
      }
      return null;
    },
    // ,
    // anonymousServicesIsActive() {
    //   if (this.activeFunction && this.activeFunction.type == "DISPLAY") {
    //     if (
    //       this.resourceResourceFunction &&
    //       this.resourceResourceFunction.data &&
    //       this.resourceResourceFunction.data.resource_function_codes
    //     ) {
    //       return this.resourceResourceFunction.data.resource_function_codes[9]
    //         .code == "1"
    //         ? true
    //         : false;
    //     }
    //   }

    //   return false;
    // },
    functionIdRouteParam() {
      const { function_id } = this.$route.params;
      if (function_id) return function_id;
      return null;
    },
  },
  mounted() {
    this.setSelectedLanguage(null);
    this.setSelectedDeviceTemplate(null);
    this.setSelectedCurrency(null);
    const { resource } = this;

    if (
      resource &&
      resource.data &&
      this.canItem(this.resource.data, "update")
    ) {
      this.callResourceData();
    } else {
      this.$router.push({ name: "r_resources" });
    }
  },
  watch: {
    functionData() {
      if (this.formKey === 10) {
        this.formKey = 0;
      } else {
        this.formKey++;
      }
    },
    functionIdRouteParam() {
      this.$store.commit("resource/setResourceResourceFunctionData", null, {
        root: true,
      });
      this.$store.commit("resource/setResourceFunctionTypeData", null, {
        root: true,
      });
      this.fieldNum = [1];
      if (this.formKey === 10) {
        this.formKey = 0;
      } else {
        this.formKey++;
      }
      // this.$commit()
      this.callResourceData();
    },
    resourceResourceFunctions() {
      this.callResourceData();
    },
    activeFunction: {
      immediate: true,
      handler() {
        this.isActive = this.activeFunction && this.activeFunction.active;
      },
    },
    resourceResourceFunction() {
      if (
        this.resourceResourceFunction &&
        this.resourceResourceFunction.data &&
        this.resourceResourceFunction.data.resource_function_codes &&
        this.resourceResourceFunction.data.resource_function_codes[9] &&
        this.resourceResourceFunction.data.resource_function_codes[9].code
      ) {
        this.anonymousServicesIsActive =
          this.resourceResourceFunction.data.resource_function_codes[9].code ==
          "1"
            ? true
            : false;
      }
    },
  },
  methods: {
    getResourceFunctionProperty(idx) {
      if (this.activeFunction.type === "DISPLAY") {
        let displayProperties = [
          "manufacturer",
          "touch",
          "led",
          "language",
          "time-format",
          "screen-size",
          "template-number",
          "serial-number",
          "screen-url",
          "password",
        ];
        return displayProperties[idx];
      }
      if (this.activeFunction.type === "COST") {
        let costProperties = ["price-per-hour", "currency"];
        return costProperties[idx];
      }
      return "code";
    },
    copyFieldValue(value) {
      const el = document.createElement("textarea");
      el.value = value;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected =
        document.getSelection().rangeCount > 0
          ? document.getSelection().getRangeAt(0)
          : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
    },
    ...mapActions("resource", [
      "getResourceFunctionType",
      "getResourceResourceFunction",
      "getResourceResourceFunctions",
      "getResourceFunctionTypes",
      "getResourceLinked",
      "getResourceDistances",
    ]),
    ...mapActions("language", ["setSelectedLanguage"]),
    ...mapActions("deviceTemplates", ["setSelectedDeviceTemplate"]),
    ...mapActions("currency", ["setSelectedCurrency"]),
    submitForm(values) {
      if (values) {
        if (values.resource_function_codes) {
          // remove empty items (only if it is not DISPLAY)
          if (values.type !== "DISPLAY")
            values.resource_function_codes = values.resource_function_codes.filter(
              (item) => item.code
            );
          // add ids
          values.resource_function_codes = values.resource_function_codes.map(
            (item, idx) => {
              if (
                this.functionData.resource_function_codes &&
                this.functionData.resource_function_codes.length &&
                this.functionData.resource_function_codes[idx] &&
                this.functionData.resource_function_codes[idx].id
              ) {
                return {
                  code: item.code,
                  id: this.functionData.resource_function_codes[idx].id,
                };
              } else {
                return item;
              }
            }
          );
        } else {
          values.resource_function_codes = [];
        }

        this.submitCompanyData(values);
      }
    },
    submitCompanyData(values) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      const { function_id } = this.$route.params;
      values.resource_function_type_id = function_id;
      values.active = Number(this.isActive);

      if (values.type == "DISPLAY") {
        // Language
        if (this.selectedLanguage && this.selectedLanguage.id) {
          values.resource_function_codes[3] = {
            id: this.functionData.resource_function_codes[3].id,
            code: this.selectedLanguage && this.selectedLanguage.id,
          };
        } else {
          values.resource_function_codes[3] = {
            id: this.functionData.resource_function_codes[3].id,
            code: this.functionData.resource_function_codes[3].code,
          };
        }
        // Device template
        if (this.selectedDeviceTemplate && this.selectedDeviceTemplate.id) {
          values.resource_function_codes[6] = {
            id: this.functionData.resource_function_codes[6].id,
            code: this.selectedDeviceTemplate && this.selectedDeviceTemplate.id,
          };
        } else {
          values.resource_function_codes[6] = {
            id: this.functionData.resource_function_codes[6].id,
            code: this.functionData.resource_function_codes[6].code,
          };
        }

        values.resource_function_codes[9] = {
          id: this.functionData.resource_function_codes[9].id,
          code: Number(this.anonymousServicesIsActive),
        };
      }

      if (values.type == "COST") {
        var valuesCost = {};
        // if currency is SELECTED
        if (this.selectedCurrency && this.selectedCurrency.id) {
          // on UPDATE
          if (
            this.functionData &&
            this.functionData.resource_function_codes &&
            this.functionData.resource_function_codes[1]
          ) {
            valuesCost.id = this.functionData.resource_function_codes[1].id;
            valuesCost.code = this.selectedCurrency && this.selectedCurrency.id;
          }
          // on ADD
          else {
            valuesCost.code = String(this.company.data.currency_id);
          }

          values.resource_function_codes[1] = valuesCost;
        }
        // if currency is NOT SELECTED
        else {
          // on UPDATE
          if (
            this.functionData &&
            this.functionData.resource_function_codes &&
            this.functionData.resource_function_codes[1]
          ) {
            valuesCost.id = this.functionData.resource_function_codes[1].id;
            valuesCost.code = this.functionData.resource_function_codes[1].code;
          }
          // on ADD
          else {
            valuesCost.code = String(this.company.data.currency_id);
          }

          values.resource_function_codes[1] = valuesCost;
        }
      }

      let method = "post";
      let url = `${apiEndpoints.company.resources}/${this.resource.data.id}/resourcefunctions`;
      if (this.functionData.resource_function_type_id) {
        method = "put";
        url += `/${this.functionData.id}`;
      }
      httpServiceAuth({
        method: method,
        url: url,
        data: values,
      })
        .then(() => {
          // this.$refs.editResourceFunctionForm.setValues({});
          this.fieldNum = [];
          // if (this.formKey === 10) {
          //   this.formKey = 0;
          // } else {
          //   this.formKey++;
          // }
          this.getResourceFunctionTypes();
          setTimeout(() => {
            this.getResourceResourceFunctions(this.resource.data.id);
          }, 0);
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          if (this.formKey === 10) {
            this.formKey = 0;
          } else {
            this.formKey++;
          }
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          if (
            Object.keys(this.alternateLayoutFunctions).includes(
              this.functionData.type
            ) &&
            this.alternateLayoutFunctions[this.functionData.type]
          ) {
            this.alternateLayoutFunctions[this.functionData.type] = false;
            this.$router.push({
              name: `r_edit-resource-edit-function-edit-${this.functionData.type.toLowerCase()}-resources`,
              params: this.$route.params,
            });
          }
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    },
    callResourceData() {
      const { function_id } = this.$route.params;
      if (
        this.resourceResourceFunctions &&
        this.resourceResourceFunctions.data &&
        this.resourceResourceFunctions.data.length
      ) {
        const filtered = this.resourceResourceFunctions.data.filter(
          (item) =>
            item.resource_function_type_id ===
            Number(this.$route.params.function_id)
        );

        if (filtered && filtered.length) {
          // TODO naci nacin da se ovo pozove izvan filtered
          this.getResourceResourceFunction({
            functionId: filtered[0].id, // resource connector id
            resourceId: this.resource.data.id,
          });
          function_id && this.getResourceFunctionType(function_id);
        } else {
          this.$store.commit("resource/setResourceResourceFunctionData", null, {
            root: true,
          });
          function_id && this.getResourceFunctionType(function_id);
        }
      } else {
        function_id && this.getResourceFunctionType(function_id);
      }
      if (
        this.functionData &&
        this.functionData.type &&
        Object.keys(this.alternateLayoutFunctions).includes(
          this.functionData.type
        )
      ) {
        switch (this.functionData.type) {
          case "LINKED":
            this.getResourceLinked(this.resource.data.id);
            break;
          case "DISTANCE":
            this.getResourceDistances(this.resource.data.id);
            break;
          default:
            break;
        }
      }
    },
    setCodeFieldRequired(codes_min, idx) {
      if (codes_min === 0 || !codes_min) {
        return false;
      }
      return idx < codes_min ? "required" : "";
    },
    addNewItem() {
      this.fieldNum.push(this.fieldNum.length || 1);
    },
    deleteItem(index) {
      this.fieldNum.splice(index, 1);
    },
    alternateFunctionsPage(resourceType) {
      if (this.isActive && this.activeFunction && !this.activeFunction.active) {
        this.alternateLayoutFunctions[resourceType] = true;
        this.initSubmit();
      } else {
        this.$router.push({
          name: `r_edit-resource-edit-function-edit-${resourceType.toLowerCase()}-resources`,
          params: this.$route.params,
        });
      }
    },
    deleteDeviceToken() {
      this.$store.commit("loader/setScreenLoading", true, {
        root: true,
      });

      httpServiceAuth
        .get(
          `${apiEndpoints.company.displayClearTokens}/${this.resource.data.id}`
        )
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.callResourceData();
          this.getResourceResourceFunction;
          this.$store.commit("loader/setScreenLoading", false, {
            root: true,
          });
        });
    },
  },
  beforeUnmount() {
    this.$store.commit("resource/setResourceResourceFunctionData", null, {
      root: true,
    });
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
  },
  props: {
    backLinkName: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.clebex-section-input-label {
  font-size: 16px !important;
}
.clebex-item-content-wrapper.alternate {
  cursor: pointer;
  label {
    cursor: pointer;
  }
}
.anonymous-services {
  color: #999999;
  font-size: 0.9375rem;
  padding: 0px;
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
  margin-left: 17px;
}
</style>
